/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TagGroup } from '../models/tag-group';
import { TagGroupPartial } from '../models/tag-group-partial';

@Injectable({
  providedIn: 'root',
})
export class TagGroupControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tagGroupControllerDuplicateTagGroupsToInventory
   */
  static readonly TagGroupControllerDuplicateTagGroupsToInventoryPath = '/admin/tag-groups/duplicate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateTagGroupsToInventory()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateTagGroupsToInventory$Response(params?: {
    sourceInventoryId?: string;
    targetInventoryId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<TagGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, TagGroupControllerService.TagGroupControllerDuplicateTagGroupsToInventoryPath, 'post');
    if (params) {
      rb.query('sourceInventoryId', params.sourceInventoryId, {});
      rb.query('targetInventoryId', params.targetInventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TagGroup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `duplicateTagGroupsToInventory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateTagGroupsToInventory(params?: {
    sourceInventoryId?: string;
    targetInventoryId?: string;
    context?: HttpContext
  }
): Observable<Array<TagGroup>> {

    return this.duplicateTagGroupsToInventory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TagGroup>>) => r.body as Array<TagGroup>)
    );
  }

  /**
   * Path part for operation tagGroupControllerAdminFindTagGroupById
   */
  static readonly TagGroupControllerAdminFindTagGroupByIdPath = '/admin/tag-groups/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindTagGroupById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindTagGroupById$Response(params: {
    id: string;
    includeTags?: boolean;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TagGroup>> {

    const rb = new RequestBuilder(this.rootUrl, TagGroupControllerService.TagGroupControllerAdminFindTagGroupByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('includeTags', params.includeTags, {});
      rb.query('nested', params.nested, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagGroup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFindTagGroupById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindTagGroupById(params: {
    id: string;
    includeTags?: boolean;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<TagGroup> {

    return this.adminFindTagGroupById$Response(params).pipe(
      map((r: StrictHttpResponse<TagGroup>) => r.body as TagGroup)
    );
  }

  /**
   * Path part for operation tagGroupControllerDeleteTagGroupById
   */
  static readonly TagGroupControllerDeleteTagGroupByIdPath = '/admin/tag-groups/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTagGroupById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagGroupById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TagGroupControllerService.TagGroupControllerDeleteTagGroupByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTagGroupById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagGroupById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteTagGroupById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation tagGroupControllerUpdateById
   */
  static readonly TagGroupControllerUpdateByIdPath = '/admin/tag-groups/{id}';

  /**
   * Update a tag group by its ID with partial data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    context?: HttpContext
    body?: TagGroupPartial
  }
): Observable<StrictHttpResponse<TagGroup>> {

    const rb = new RequestBuilder(this.rootUrl, TagGroupControllerService.TagGroupControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagGroup>;
      })
    );
  }

  /**
   * Update a tag group by its ID with partial data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    context?: HttpContext
    body?: TagGroupPartial
  }
): Observable<TagGroup> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<TagGroup>) => r.body as TagGroup)
    );
  }

  /**
   * Path part for operation tagGroupControllerFindAll
   */
  static readonly TagGroupControllerFindAllPath = '/admin/tag-groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(params?: {
    includeTags?: boolean;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<TagGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, TagGroupControllerService.TagGroupControllerFindAllPath, 'get');
    if (params) {
      rb.query('includeTags', params.includeTags, {});
      rb.query('nested', params.nested, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TagGroup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(params?: {
    includeTags?: boolean;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<Array<TagGroup>> {

    return this.findAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TagGroup>>) => r.body as Array<TagGroup>)
    );
  }

  /**
   * Path part for operation tagGroupControllerCreateTagGroup
   */
  static readonly TagGroupControllerCreateTagGroupPath = '/admin/tag-groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTagGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTagGroup$Response(params?: {
    context?: HttpContext
    body?: TagGroup
  }
): Observable<StrictHttpResponse<TagGroup>> {

    const rb = new RequestBuilder(this.rootUrl, TagGroupControllerService.TagGroupControllerCreateTagGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagGroup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTagGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTagGroup(params?: {
    context?: HttpContext
    body?: TagGroup
  }
): Observable<TagGroup> {

    return this.createTagGroup$Response(params).pipe(
      map((r: StrictHttpResponse<TagGroup>) => r.body as TagGroup)
    );
  }

}
