import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationService } from "../../services/navigation.service";
import { LayoutService } from "../../services/layout.service";
import { ConfigService } from "../../config/config.service";
import { map, startWith, switchMap } from "rxjs/operators";
import {
  NavigationItem,
  NavigationLink,
} from "../../interfaces/navigation-item.interface";
import { PopoverService } from "../../components/popover/popover.service";
import { Observable, of, Subscription } from "rxjs";
import { UserMenuComponent } from "../../components/user-menu/user-menu.component";
import { MatDialog } from "@angular/material/dialog";
import { SearchModalComponent } from "../../components/search-modal/search-modal.component";
import { AuthService } from "src/app/_services/auth.service";
import { UserInfo } from "src/app/api/models";
import { CustomNavMenuService } from "src/app/shared/services/custom-nav-menu.service";

@Component({
  selector: "vex-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(
    map((config) => config.sidenav.title)
  );
  imageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.imageUrl)
  );
  showCollapsePin$ = this.configService.config$.pipe(
    map((config) => config.sidenav.showCollapsePin)
  );
  userVisible$ = this.configService.config$.pipe(
    map((config) => config.sidenav.user.visible)
  );
  searchVisible$ = this.configService.config$.pipe(
    map((config) => config.sidenav.search.visible)
  );

  userMenuOpen$: Observable<boolean> = of(false);

  items: NavigationItem[] = [];

  currentUser: UserInfo = undefined;
  currentUserSubscription: Subscription = new Subscription();

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private readonly popoverService: PopoverService,
    private readonly dialog: MatDialog,
    private authService: AuthService,
    private customNavMenuService: CustomNavMenuService
  ) {}
  ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
  }

  ngOnInit() {
    this.currentUserSubscription = this.authService.currentUser$.subscribe(
      async (data) => {
        this.currentUser = data;
        if (this.currentUser) {
          await this.customNavMenuService.createMenu();
        } else {
          this.customNavMenuService.clearMenu();
        }
        this.items = this.customNavMenuService.getMenu();
      }
    );
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed
      ? this.layoutService.expandSidenav()
      : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: "center",
            originY: "top",
            overlayX: "center",
            overlayY: "bottom",
          },
        ],
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: "vex-dialog-glossy",
      width: "100%",
      maxWidth: "600px",
    });
  }
}
