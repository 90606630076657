/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OwnerTypeWithRelations } from '../models/owner-type-with-relations';

@Injectable({
  providedIn: 'root',
})
export class OwnerTypeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation ownerTypeControllerGetOwnerTypesList
   */
  static readonly OwnerTypeControllerGetOwnerTypesListPath = '/owner-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOwnerTypesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOwnerTypesList$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OwnerTypeWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, OwnerTypeControllerService.OwnerTypeControllerGetOwnerTypesListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OwnerTypeWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOwnerTypesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOwnerTypesList(params?: {
    context?: HttpContext
  }
): Observable<Array<OwnerTypeWithRelations>> {

    return this.getOwnerTypesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OwnerTypeWithRelations>>) => r.body as Array<OwnerTypeWithRelations>)
    );
  }

}
