import { Injectable } from "@angular/core";
import { ClickDirective } from "angular-calendar/modules/common/click.directive";
import {
  NavigationItem,
  NavigationSubheading,
} from "src/@vex/interfaces/navigation-item.interface";
import { NavigationService } from "src/@vex/services/navigation.service";
import { InventoryControllerService } from "src/app/api/services";
import { AuthService } from "src/app/_services/auth.service";

@Injectable({
  providedIn: "root",
})
export class CustomNavMenuService {
  constructor(
    private authService: AuthService,
    private inventoryService: InventoryControllerService,
    private navigationService: NavigationService
  ) {}

  private NO_INVENTORY: NavigationItem = {
    type: "link",
    label: "Nemáte žádný soupis k dispozici",
    availableTo: ["root", "admin", "editor", "user"],
    route: "",
    icon: "mat:highlight_off",
    routerLinkActiveOptions: { exact: true },
  };

  private INVENTORY_BADGES = {
    owner: {
      value: "M",
      bgClass: "bg-purple",
      textClass: "text-purple-contrast",
    },
    manager: {
      value: "S",
      bgClass: "bg-teal",
      textClass: "text-teal-contrast",
    },
    curator: {
      value: "K",
      bgClass: "bg-orange",
      textClass: "text-orange-contrast",
    },
    viewer: {
      value: "P",
      bgClass: "bg-gray",
      textClass: "text-gray-contrast",
    },
  };

  /**
   * NAVIGATION ITEMS
   */
  private DEFAULT_NAV_MENU_ITEMS: NavigationItem[] = [
    {
      type: "subheading",
      label: "Číselníky",
      availableTo: ["root", "admin", "editor"],
      children: [
        {
          type: "link",
          label: "Aukce",
          availableTo: ["root", "admin", "editor"],
          route: "/enum/auctions",
          icon: "mat:collections",
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: "link",
          label: "Autoři",
          availableTo: ["root", "admin", "editor"],
          route: "/enum/authors",
          icon: "mat:co_present",
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: "link",
          label: "Kontakty",
          availableTo: ["root", "admin", "editor"],
          route: "/enum/contacts",
          icon: "mat:contacts",
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: "link",
          label: "Publikace",
          availableTo: ["root", "admin", "editor"],
          route: "/enum/publications",
          icon: "mat:library_books",
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: "link",
          label: "Výstavy",
          availableTo: ["root", "admin", "editor"],
          route: "/enum/exhibitions",
          icon: "mat:recent_actors",
          routerLinkActiveOptions: { exact: true },
        },
      ],
    },
    {
      type: "subheading",
      label: "admin",
      availableTo: ["root", "admin"],
      children: [
        {
          type: "link",
          label: "Soupisy",
          availableTo: ["root", "admin"],
          route: "/admin/inventories",
          icon: "mat:perm_media",
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: "link",
          label: "Uživatelé",
          availableTo: ["root", "admin"],
          route: "/admin/users",
          icon: "mat:manage_accounts",
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: "link",
          label: "Návštěvníci",
          availableTo: ["root", "admin"],
          route: "/admin/visitors",
          icon: "mat:recent_actors",
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: "link",
          label: "Systémový log",
          availableTo: ["root", "admin"],
          route: "/admin/log",
          icon: "mat:hub",
          routerLinkActiveOptions: { exact: true },
        },
      ],
    },
  ];

  async createMenu(): Promise<void> {
    if (!this.navigationService.menuIsReady) {
      this.navigationService.items = [];
      this.navigationService.items = this.DEFAULT_NAV_MENU_ITEMS;
      await this.prepareInventoryMenuItems();
      this.navigationService.menuIsReady = true;
    }
  }

  clearMenu(): void {
    this.navigationService.items = [];
    this.navigationService.menuIsReady = false;
  }

  getMenu(): NavigationItem[] {
    const menuItems: NavigationItem[] = this.navigationService.items;
    return menuItems;
  }

  //FIXME: when user logout and do not refresh page, after new login is previous user's inventories visibel
  //QUICKFIX: In login component is set hard window.reload() after successful login
  private async prepareInventoryMenuItems(): Promise<void> {
    const inventoryNavItems = [];
    const currentUserInventoryPermissions =
      this.authService.currentUserValue.inventoryPermissions;
    const inventoryRoles = await this.inventoryService
      .getInventoryRoles()
      .toPromise();

    if (currentUserInventoryPermissions.length > 0) {
      await Promise.all(
        currentUserInventoryPermissions.map(async (cuip) => {
          const inventory = await this.inventoryService
            .findById({ id: cuip.inventoryId })
            .toPromise();
          const userPermission = inventoryRoles.find(
            (ir) => ir.id === cuip.permissionId
          );
          const navItem: NavigationItem = {
            type: "link",
            label: inventory.mainTitle,
            availableTo: ["root", "admin", "editor", "user"],
            route: `/inventory/${inventory.inventoryCode.toLocaleLowerCase()}/manager/0`,
            icon: "mat:rectangle",
            routerLinkActiveOptions: { exact: true },
            badge: this.INVENTORY_BADGES[userPermission.code],
          };
          inventoryNavItems.push(navItem);
        })
      );

      inventoryNavItems.sort((a: NavigationItem, b: NavigationItem) =>
        a.label.localeCompare(b.label)
      );

      const inventoryNavSection: NavigationSubheading = {
        type: "subheading",
        label: "Soupisy",
        availableTo: ["root", "admin", "editor", "user"],
        children: inventoryNavItems,
      };
      this.navigationService.items.unshift(inventoryNavSection);
    }
  }
}
