/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Auction } from '../models/auction';
import { AuctionPartial } from '../models/auction-partial';
import { AuctionWithRelations } from '../models/auction-with-relations';
import { AuctionWithTranslationsPartial } from '../models/auction-with-translations-partial';
import { ItemAuctionWithRelations } from '../models/item-auction-with-relations';
import { NewAuction } from '../models/new-auction';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class AdminAuctionsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation auctionControllerGetList
   */
  static readonly AuctionControllerGetListPath = '/admin/auctions/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList$Response(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AuctionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.AuctionControllerGetListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AuctionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<Array<AuctionWithRelations>> {

    return this.getList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AuctionWithRelations>>) => r.body as Array<AuctionWithRelations>)
    );
  }

  /**
   * Path part for operation itemAuctionControllerAdminGetListForAuction
   */
  static readonly ItemAuctionControllerAdminGetListForAuctionPath = '/admin/auctions/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetListForAuction()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForAuction$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemAuctionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.ItemAuctionControllerAdminGetListForAuctionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemAuctionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetListForAuction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForAuction(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<ItemAuctionWithRelations>> {

    return this.adminGetListForAuction$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemAuctionWithRelations>>) => r.body as Array<ItemAuctionWithRelations>)
    );
  }

  /**
   * Path part for operation auctionControllerAdminFindById
   */
  static readonly AuctionControllerAdminFindByIdPath = '/admin/auctions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: {
    id: string;
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuctionWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.AuctionControllerAdminFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuctionWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: {
    id: string;
    filter?: any;
    context?: HttpContext
  }
): Observable<AuctionWithRelations> {

    return this.adminFindById$Response(params).pipe(
      map((r: StrictHttpResponse<AuctionWithRelations>) => r.body as AuctionWithRelations)
    );
  }

  /**
   * Path part for operation auctionControllerReplaceById
   */
  static readonly AuctionControllerReplaceByIdPath = '/admin/auctions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById$Response(params: {
    id: string;
    context?: HttpContext
    body?: Auction
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.AuctionControllerReplaceByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replaceById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById(params: {
    id: string;
    context?: HttpContext
    body?: Auction
  }
): Observable<any> {

    return this.replaceById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation auctionControllerAdminDeleteById
   */
  static readonly AuctionControllerAdminDeleteByIdPath = '/admin/auctions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.AuctionControllerAdminDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.adminDeleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation auctionControllerUpdateById
   */
  static readonly AuctionControllerUpdateByIdPath = '/admin/auctions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    context?: HttpContext
    body?: AuctionWithTranslationsPartial
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.AuctionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    context?: HttpContext
    body?: AuctionWithTranslationsPartial
  }
): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation auctionControllerAdminFind
   */
  static readonly AuctionControllerAdminFindPath = '/admin/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AuctionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.AuctionControllerAdminFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AuctionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<Array<AuctionWithRelations>> {

    return this.adminFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AuctionWithRelations>>) => r.body as Array<AuctionWithRelations>)
    );
  }

  /**
   * Path part for operation auctionControllerCreate
   */
  static readonly AuctionControllerCreatePath = '/admin/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    context?: HttpContext
    body?: NewAuction
  }
): Observable<StrictHttpResponse<Auction>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.AuctionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Auction>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    context?: HttpContext
    body?: NewAuction
  }
): Observable<Auction> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Auction>) => r.body as Auction)
    );
  }

  /**
   * Path part for operation auctionControllerUpdateAll
   */
  static readonly AuctionControllerUpdateAllPath = '/admin/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll$Response(params?: {
    where?: any;
    context?: HttpContext
    body?: AuctionPartial
  }
): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, AdminAuctionsControllerService.AuctionControllerUpdateAllPath, 'patch');
    if (params) {
      rb.query('where', params.where, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll(params?: {
    where?: any;
    context?: HttpContext
    body?: AuctionPartial
  }
): Observable<LoopbackCount> {

    return this.updateAll$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

}
