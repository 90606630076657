import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AuthGuard } from "./_guards/auth.guard";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./pages/auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "",
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    canLoad: [AuthGuard],
    canDeactivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        redirectTo: "/",
        pathMatch: "full",
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./pages/inventory/inventory.module").then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: "item",
        loadChildren: () =>
          import("./pages/item/item.module").then(
            (m) => m.ItemModule
          ),
      },
      {
        path: "enum",
        loadChildren: () =>
          import("./pages/enums/enums.module").then((m) => m.EnumsModule),
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./pages/admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./pages/user/user.module").then((m) => m.UserModule),
      },
    ],
  },

  {
    path: "**",
    loadChildren: () =>
      import("./pages/errors/error-404/error-404.module").then(
        (m) => m.Error404Module
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
