/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ItemPublicationWithRelations } from '../models/item-publication-with-relations';
import { NewPublication } from '../models/new-publication';
import { Publication } from '../models/publication';
import { PublicationPartial } from '../models/publication-partial';
import { PublicationWithRelations } from '../models/publication-with-relations';
import { PublicationWithTranslationsPartial } from '../models/publication-with-translations-partial';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class AdminPublicationsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation publicationControllerAdminGetList
   */
  static readonly PublicationControllerAdminGetListPath = '/admin/publications/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetList$Response(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PublicationWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerAdminGetListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PublicationWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetList(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<Array<PublicationWithRelations>> {

    return this.adminGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PublicationWithRelations>>) => r.body as Array<PublicationWithRelations>)
    );
  }

  /**
   * Path part for operation publicationControllerApprove
   */
  static readonly PublicationControllerApprovePath = '/admin/publications/{id}/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approve()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerApprovePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.approve$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation itemPublicationControllerAdminGetListForPublication
   */
  static readonly ItemPublicationControllerAdminGetListForPublicationPath = '/admin/publications/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetListForPublication()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForPublication$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemPublicationWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.ItemPublicationControllerAdminGetListForPublicationPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemPublicationWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetListForPublication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForPublication(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<ItemPublicationWithRelations>> {

    return this.adminGetListForPublication$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemPublicationWithRelations>>) => r.body as Array<ItemPublicationWithRelations>)
    );
  }

  /**
   * Path part for operation publicationControllerAdminFindById
   */
  static readonly PublicationControllerAdminFindByIdPath = '/admin/publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: {
    id: string;
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PublicationWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerAdminFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicationWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: {
    id: string;
    filter?: any;
    context?: HttpContext
  }
): Observable<PublicationWithRelations> {

    return this.adminFindById$Response(params).pipe(
      map((r: StrictHttpResponse<PublicationWithRelations>) => r.body as PublicationWithRelations)
    );
  }

  /**
   * Path part for operation publicationControllerReplaceById
   */
  static readonly PublicationControllerReplaceByIdPath = '/admin/publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById$Response(params: {
    id: string;
    context?: HttpContext
    body?: Publication
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerReplaceByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replaceById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById(params: {
    id: string;
    context?: HttpContext
    body?: Publication
  }
): Observable<any> {

    return this.replaceById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation publicationControllerAdminDeleteById
   */
  static readonly PublicationControllerAdminDeleteByIdPath = '/admin/publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerAdminDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.adminDeleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation publicationControllerUpdateById
   */
  static readonly PublicationControllerUpdateByIdPath = '/admin/publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    context?: HttpContext
    body?: PublicationWithTranslationsPartial
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    context?: HttpContext
    body?: PublicationWithTranslationsPartial
  }
): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation publicationControllerAdminFind
   */
  static readonly PublicationControllerAdminFindPath = '/admin/publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PublicationWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerAdminFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PublicationWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<Array<PublicationWithRelations>> {

    return this.adminFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PublicationWithRelations>>) => r.body as Array<PublicationWithRelations>)
    );
  }

  /**
   * Path part for operation publicationControllerCreate
   */
  static readonly PublicationControllerCreatePath = '/admin/publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    context?: HttpContext
    body?: NewPublication
  }
): Observable<StrictHttpResponse<Publication>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Publication>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    context?: HttpContext
    body?: NewPublication
  }
): Observable<Publication> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Publication>) => r.body as Publication)
    );
  }

  /**
   * Path part for operation publicationControllerUpdateAll
   */
  static readonly PublicationControllerUpdateAllPath = '/admin/publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll$Response(params?: {
    where?: any;
    context?: HttpContext
    body?: PublicationPartial
  }
): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, AdminPublicationsControllerService.PublicationControllerUpdateAllPath, 'patch');
    if (params) {
      rb.query('where', params.where, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll(params?: {
    where?: any;
    context?: HttpContext
    body?: PublicationPartial
  }
): Observable<LoopbackCount> {

    return this.updateAll$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

}
