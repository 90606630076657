<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar w-full px-gutter flex items-center"
>
  <button
    (click)="openSidenav()"
    [class.hidden]="!mobileQuery"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>
  <button
    (click)="toggleCollapse()"
    class="w-14 h-14 p-0 leading-none hidden lg:!block"
    mat-icon-button
    type="button"
  >
    <mat-icon
      *ngIf="!collapsed"
      class="w-6 h-6"
      svgIcon="mat:radio_button_checked"
    ></mat-icon>
    <mat-icon
      *ngIf="collapsed"
      class="w-6 h-6"
      svgIcon="mat:radio_button_unchecked"
    ></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 block flex items-center"
  >
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg" />
    <h1
      [class.hidden]="!mobileQuery"
      class="title ltr:pl-4 rtl:pr-4 m-0 select-none"
    >
      VEX
    </h1>
  </a>

  <div
    *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
    [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center"
  >
    <vex-navigation-item
      *ngFor="let item of navigationItems"
      [item]="item"
    ></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center"></div>
</div>

<vex-navigation
  *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"
></vex-navigation>
