import { MatPaginatorIntl } from "@angular/material/paginator";

/**
 * Defines the structure for localizing paginator labels.
 */
interface PaginatorLocalization {
  itemsPerPageLabel: string; // Label for items per page dropdown
  firstPageLabel: string; // Label for the first page button
  lastPageLabel: string; // Label for the last page button
  nextPageLabel: string; // Label for the next page button
  previousPageLabel: string; // Label for the previous page button
  ofLabel: string; // Label used to indicate the total number of pages (e.g., "of")
}

/**
 * Type for supported languages.
 */
type Language = "cs" | "en"; // Supported languages: Czech ('cs') and English ('en')

/**
 * Object containing localizations for paginator controls.
 * Each language has its own set of labels for the paginator component.
 */
const PAGINATOR_LOCALIZATION: Record<Language, PaginatorLocalization> = {
  cs: {
    // Czech localization
    itemsPerPageLabel: "Počet záznamů na stránku",
    firstPageLabel: "První stránka",
    lastPageLabel: "Poslední stránka",
    nextPageLabel: "Další stránka",
    previousPageLabel: "Předchozí stránka",
    ofLabel: "z",
  },
  en: {
    // English localization
    itemsPerPageLabel: "Items per page",
    firstPageLabel: "First page",
    lastPageLabel: "Last page",
    nextPageLabel: "Next page",
    previousPageLabel: "Previous page",
    ofLabel: "of",
  },
};

/**
 * Generates a customized paginator internationalization for Angular Material Paginator.
 * It supports multiple languages by providing localization for paginator labels.
 *
 * @param {Language} lang - The language code ('cs' for Czech, 'en' for English) to set the paginator labels.
 * @returns {MatPaginatorIntl} A MatPaginatorIntl instance with customized labels and functions based on the specified language.
 *
 * This function customizes the Angular Material Paginator's labels such as items per page label, navigation button labels (first, last, next, previous),
 * and the range label which shows the current range of items being displayed. It dynamically sets these labels based on the provided language parameter.
 * The default language is set to Czech ('cs'). If the language is not specified, it will fallback to Czech localization.
 * The `getRangeLabel` function inside it is used to customize the text displaying the range of items and the total number of items,
 * which also adheres to the localization specified by the `lang` parameter.
 */
export function getPaginatorIntl(lang: Language = "cs"): MatPaginatorIntl {
  // Extract the localization strings for the specified language
  const {
    itemsPerPageLabel,
    firstPageLabel,
    lastPageLabel,
    nextPageLabel,
    previousPageLabel,
    ofLabel,
  } = PAGINATOR_LOCALIZATION[lang];

  // Function to calculate and return the text for the range label
  const getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${ofLabel} ${length}`;
    }
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${ofLabel} ${length}`;
  };

  // Create a new instance of MatPaginatorIntl with customized labels
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = itemsPerPageLabel;
  paginatorIntl.firstPageLabel = firstPageLabel;
  paginatorIntl.lastPageLabel = lastPageLabel;
  paginatorIntl.nextPageLabel = nextPageLabel;
  paginatorIntl.previousPageLabel = previousPageLabel;
  paginatorIntl.getRangeLabel = getRangeLabel;

  return paginatorIntl;
}
