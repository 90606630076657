/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Tag } from '../models/tag';
import { TagPartial } from '../models/tag-partial';

@Injectable({
  providedIn: 'root',
})
export class TagControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tagControllerFindTagByIdAdmin
   */
  static readonly TagControllerFindTagByIdAdminPath = '/admin/tags/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTagByIdAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTagByIdAdmin$Response(params: {
    id: string;
    children?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, TagControllerService.TagControllerFindTagByIdAdminPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('children', params.children, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTagByIdAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTagByIdAdmin(params: {
    id: string;
    children?: boolean;
    context?: HttpContext
  }
): Observable<Tag> {

    return this.findTagByIdAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

  /**
   * Path part for operation tagControllerDeleteTagById
   */
  static readonly TagControllerDeleteTagByIdPath = '/admin/tags/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTagById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TagControllerService.TagControllerDeleteTagByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTagById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteTagById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation tagControllerUpdateById
   */
  static readonly TagControllerUpdateByIdPath = '/admin/tags/{id}';

  /**
   * Update a tag gby its ID with partial data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    context?: HttpContext
    body?: TagPartial
  }
): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, TagControllerService.TagControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * Update a tag gby its ID with partial data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    context?: HttpContext
    body?: TagPartial
  }
): Observable<Tag> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

  /**
   * Path part for operation tagControllerFindTagsAdmin
   */
  static readonly TagControllerFindTagsAdminPath = '/admin/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTagsAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTagsAdmin$Response(params: {
    nested: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Tag>>> {

    const rb = new RequestBuilder(this.rootUrl, TagControllerService.TagControllerFindTagsAdminPath, 'get');
    if (params) {
      rb.query('nested', params.nested, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Tag>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTagsAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTagsAdmin(params: {
    nested: boolean;
    context?: HttpContext
  }
): Observable<Array<Tag>> {

    return this.findTagsAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Tag>>) => r.body as Array<Tag>)
    );
  }

  /**
   * Path part for operation tagControllerCreateTag
   */
  static readonly TagControllerCreateTagPath = '/admin/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag$Response(params?: {
    context?: HttpContext
    body?: Tag
  }
): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, TagControllerService.TagControllerCreateTagPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag(params?: {
    context?: HttpContext
    body?: Tag
  }
): Observable<Tag> {

    return this.createTag$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

}
