/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FileWithRelations } from '../models/file-with-relations';
import { ItemWithRelations } from '../models/item-with-relations';

@Injectable({
  providedIn: 'root',
})
export class ItemControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation itemControllerGetDocuments
   */
  static readonly ItemControllerGetDocumentsPath = '/items/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FileWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemControllerService.ItemControllerGetDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FileWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<FileWithRelations>> {

    return this.getDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>) => r.body as Array<FileWithRelations>)
    );
  }

  /**
   * Path part for operation itemControllerGetPictures
   */
  static readonly ItemControllerGetPicturesPath = '/items/{id}/pictures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPictures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPictures$Response(params: {
    id: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FileWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemControllerService.ItemControllerGetPicturesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('inventoryId', params.inventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FileWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPictures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPictures(params: {
    id: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<Array<FileWithRelations>> {

    return this.getPictures$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>) => r.body as Array<FileWithRelations>)
    );
  }

  /**
   * Path part for operation itemControllerFindById
   */
  static readonly ItemControllerFindByIdPath = '/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params?: {
    itemId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ItemWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ItemControllerService.ItemControllerFindByIdPath, 'get');
    if (params) {
      rb.query('itemId', params.itemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ItemWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params?: {
    itemId?: string;
    context?: HttpContext
  }
): Observable<ItemWithRelations> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ItemWithRelations>) => r.body as ItemWithRelations)
    );
  }

}
