/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InventoryUser } from '../models/inventory-user';
import { User } from '../models/user';
import { UserInfo } from '../models/user-info';

@Injectable({
  providedIn: 'root',
})
export class AdminUserControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation adminUserControllerGetAllUsersInfo
   */
  static readonly AdminUserControllerGetAllUsersInfoPath = '/admin/users/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsersInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsersInfo$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerGetAllUsersInfoPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUsersInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsersInfo(params?: {
    context?: HttpContext
  }
): Observable<Array<UserInfo>> {

    return this.getAllUsersInfo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserInfo>>) => r.body as Array<UserInfo>)
    );
  }

  /**
   * Path part for operation adminUserControllerResetUserPassword
   */
  static readonly AdminUserControllerResetUserPasswordPath = '/admin/users/{id}/change-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetUserPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetUserPassword$Response(params: {
    id: string;
    newPassword?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerResetUserPasswordPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('newPassword', params.newPassword, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetUserPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetUserPassword(params: {
    id: string;
    newPassword?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.resetUserPassword$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation adminUserControllerGetUserInfoById
   */
  static readonly AdminUserControllerGetUserInfoByIdPath = '/admin/users/{id}/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInfoById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfoById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserInfo>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerGetUserInfoByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserInfoById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfoById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<UserInfo> {

    return this.getUserInfoById$Response(params).pipe(
      map((r: StrictHttpResponse<UserInfo>) => r.body as UserInfo)
    );
  }

  /**
   * Path part for operation adminUserControllerGetUserInventoryRoles
   */
  static readonly AdminUserControllerGetUserInventoryRolesPath = '/admin/users/{id}/inventory-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInventoryRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInventoryRoles$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<InventoryUser>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerGetUserInventoryRolesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InventoryUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserInventoryRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInventoryRoles(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<InventoryUser>> {

    return this.getUserInventoryRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InventoryUser>>) => r.body as Array<InventoryUser>)
    );
  }

  /**
   * Path part for operation adminUserControllerSetActiveStateToUser
   */
  static readonly AdminUserControllerSetActiveStateToUserPath = '/admin/users/{id}/state';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActiveStateToUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveStateToUser$Response(params: {
    id: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerSetActiveStateToUserPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setActiveStateToUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveStateToUser(params: {
    id: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<void> {

    return this.setActiveStateToUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminUserControllerGetUserById
   */
  static readonly AdminUserControllerGetUserByIdPath = '/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerGetUserByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<User> {

    return this.getUserById$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation adminUserControllerDeleteUser
   */
  static readonly AdminUserControllerDeleteUserPath = '/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerDeleteUserPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminUserControllerPatchUserById
   */
  static readonly AdminUserControllerPatchUserByIdPath = '/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserById$Response(params: {
    id: string;
    context?: HttpContext
    body?: User
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerPatchUserByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchUserById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserById(params: {
    id: string;
    context?: HttpContext
    body?: User
  }
): Observable<boolean> {

    return this.patchUserById$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation adminUserControllerGetAllUsers
   */
  static readonly AdminUserControllerGetAllUsersPath = '/admin/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUserControllerService.AdminUserControllerGetAllUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers(params?: {
    context?: HttpContext
  }
): Observable<Array<User>> {

    return this.getAllUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

}
