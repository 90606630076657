import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VexModule } from "../@vex/vex.module";
import { HttpClientModule } from "@angular/common/http";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { AuthService } from "./_services/auth.service";
import { AuthGuard } from "./_guards/auth.guard";
import { PermissionGuard } from "./_guards/permission.guard";
import { AuthInterceptorProviders } from "./_http-interceptors";
import { ApiModule } from "./api/api.module";
import { environment } from "src/environments/environment";

import { getPaginatorIntl } from "./shared/utils/paginator.translate";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { DatePipe } from "@angular/common";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApiModule.forRoot({ rootUrl: environment.baseApiUrl }),

    // Vex
    VexModule,
    CustomLayoutModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    PermissionGuard,
    AuthInterceptorProviders,
    DatePipe,
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
