/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Location } from '../models/location';
import { LocationPartial } from '../models/location-partial';
import { LocationWithRelations } from '../models/location-with-relations';

@Injectable({
  providedIn: 'root',
})
export class LocationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation locationControllerAdminFindById
   */
  static readonly LocationControllerAdminFindByIdPath = '/admin/locations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: {
    id: string;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LocationWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, LocationControllerService.LocationControllerAdminFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('nested', params.nested, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: {
    id: string;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<LocationWithRelations> {

    return this.adminFindById$Response(params).pipe(
      map((r: StrictHttpResponse<LocationWithRelations>) => r.body as LocationWithRelations)
    );
  }

  /**
   * Path part for operation locationControllerDeleteLocationById
   */
  static readonly LocationControllerDeleteLocationByIdPath = '/admin/locations/{id}';

  /**
   * Delete location by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLocationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLocationById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocationControllerService.LocationControllerDeleteLocationByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete location by its ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteLocationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLocationById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteLocationById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation locationControllerUpdateById
   */
  static readonly LocationControllerUpdateByIdPath = '/admin/locations/{id}';

  /**
   * Update location by its ID with partial data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    context?: HttpContext
    body?: LocationPartial
  }
): Observable<StrictHttpResponse<Location>> {

    const rb = new RequestBuilder(this.rootUrl, LocationControllerService.LocationControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Location>;
      })
    );
  }

  /**
   * Update location by its ID with partial data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    context?: HttpContext
    body?: LocationPartial
  }
): Observable<Location> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<Location>) => r.body as Location)
    );
  }

  /**
   * Path part for operation locationControllerAdminFind
   */
  static readonly LocationControllerAdminFindPath = '/admin/locations';

  /**
   * Get locations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: {
    inventoryId?: string;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LocationWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, LocationControllerService.LocationControllerAdminFindPath, 'get');
    if (params) {
      rb.query('inventoryId', params.inventoryId, {});
      rb.query('nested', params.nested, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationWithRelations>>;
      })
    );
  }

  /**
   * Get locations
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: {
    inventoryId?: string;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<Array<LocationWithRelations>> {

    return this.adminFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationWithRelations>>) => r.body as Array<LocationWithRelations>)
    );
  }

  /**
   * Path part for operation locationControllerCreate
   */
  static readonly LocationControllerCreatePath = '/admin/locations';

  /**
   * Create a new location
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    context?: HttpContext
    body?: LocationPartial
  }
): Observable<StrictHttpResponse<Location>> {

    const rb = new RequestBuilder(this.rootUrl, LocationControllerService.LocationControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Location>;
      })
    );
  }

  /**
   * Create a new location
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    context?: HttpContext
    body?: LocationPartial
  }
): Observable<Location> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Location>) => r.body as Location)
    );
  }

}
