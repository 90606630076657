/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Exhibition } from '../models/exhibition';
import { ExhibitionPartial } from '../models/exhibition-partial';
import { ExhibitionWithRelations } from '../models/exhibition-with-relations';
import { ExhibitionWithTranslationsPartial } from '../models/exhibition-with-translations-partial';
import { ItemExhibitionWithRelations } from '../models/item-exhibition-with-relations';
import { NewExhibition } from '../models/new-exhibition';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class AdminExhibitionsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation exhibitionControllerGetList
   */
  static readonly ExhibitionControllerGetListPath = '/admin/exhibitions/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList$Response(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ExhibitionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerGetListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExhibitionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<Array<ExhibitionWithRelations>> {

    return this.getList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExhibitionWithRelations>>) => r.body as Array<ExhibitionWithRelations>)
    );
  }

  /**
   * Path part for operation exhibitionControllerApprove
   */
  static readonly ExhibitionControllerApprovePath = '/admin/exhibitions/{id}/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approve()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerApprovePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.approve$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation itemExhibitionControllerAdminGetListForExhibition
   */
  static readonly ItemExhibitionControllerAdminGetListForExhibitionPath = '/admin/exhibitions/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetListForExhibition()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForExhibition$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemExhibitionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ItemExhibitionControllerAdminGetListForExhibitionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemExhibitionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetListForExhibition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForExhibition(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<ItemExhibitionWithRelations>> {

    return this.adminGetListForExhibition$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemExhibitionWithRelations>>) => r.body as Array<ItemExhibitionWithRelations>)
    );
  }

  /**
   * Path part for operation exhibitionControllerAdminFindById
   */
  static readonly ExhibitionControllerAdminFindByIdPath = '/admin/exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: {
    id: string;
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ExhibitionWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerAdminFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExhibitionWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: {
    id: string;
    filter?: any;
    context?: HttpContext
  }
): Observable<ExhibitionWithRelations> {

    return this.adminFindById$Response(params).pipe(
      map((r: StrictHttpResponse<ExhibitionWithRelations>) => r.body as ExhibitionWithRelations)
    );
  }

  /**
   * Path part for operation exhibitionControllerReplaceById
   */
  static readonly ExhibitionControllerReplaceByIdPath = '/admin/exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById$Response(params: {
    id: string;
    context?: HttpContext
    body?: Exhibition
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerReplaceByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replaceById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById(params: {
    id: string;
    context?: HttpContext
    body?: Exhibition
  }
): Observable<any> {

    return this.replaceById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exhibitionControllerAdminDeleteById
   */
  static readonly ExhibitionControllerAdminDeleteByIdPath = '/admin/exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerAdminDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.adminDeleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exhibitionControllerUpdateById
   */
  static readonly ExhibitionControllerUpdateByIdPath = '/admin/exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    context?: HttpContext
    body?: ExhibitionWithTranslationsPartial
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    context?: HttpContext
    body?: ExhibitionWithTranslationsPartial
  }
): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exhibitionControllerAdminFind
   */
  static readonly ExhibitionControllerAdminFindPath = '/admin/exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ExhibitionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerAdminFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExhibitionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<Array<ExhibitionWithRelations>> {

    return this.adminFind$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExhibitionWithRelations>>) => r.body as Array<ExhibitionWithRelations>)
    );
  }

  /**
   * Path part for operation exhibitionControllerCreate
   */
  static readonly ExhibitionControllerCreatePath = '/admin/exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    context?: HttpContext
    body?: NewExhibition
  }
): Observable<StrictHttpResponse<Exhibition>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Exhibition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    context?: HttpContext
    body?: NewExhibition
  }
): Observable<Exhibition> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Exhibition>) => r.body as Exhibition)
    );
  }

  /**
   * Path part for operation exhibitionControllerUpdateAll
   */
  static readonly ExhibitionControllerUpdateAllPath = '/admin/exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll$Response(params?: {
    where?: any;
    context?: HttpContext
    body?: ExhibitionPartial
  }
): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, AdminExhibitionsControllerService.ExhibitionControllerUpdateAllPath, 'patch');
    if (params) {
      rb.query('where', params.where, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll(params?: {
    where?: any;
    context?: HttpContext
    body?: ExhibitionPartial
  }
): Observable<LoopbackCount> {

    return this.updateAll$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

}
