/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ItemPublicationPartialWithRelations } from '../models/item-publication-partial-with-relations';
import { ItemPublicationWithRelations } from '../models/item-publication-with-relations';

@Injectable({
  providedIn: 'root',
})
export class AdminItemPublicationsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation itemPublicationControllerAdminFindById
   */
  static readonly ItemPublicationControllerAdminFindByIdPath = '/admin/item-publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ItemPublicationWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemPublicationsControllerService.ItemPublicationControllerAdminFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ItemPublicationWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<ItemPublicationWithRelations> {

    return this.adminFindById$Response(params).pipe(
      map((r: StrictHttpResponse<ItemPublicationWithRelations>) => r.body as ItemPublicationWithRelations)
    );
  }

  /**
   * Path part for operation itemPublicationControllerAdminDeleteById
   */
  static readonly ItemPublicationControllerAdminDeleteByIdPath = '/admin/item-publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemPublicationsControllerService.ItemPublicationControllerAdminDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.adminDeleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation itemPublicationControllerUpdateById
   */
  static readonly ItemPublicationControllerUpdateByIdPath = '/admin/item-publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    context?: HttpContext
    body?: ItemPublicationPartialWithRelations
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemPublicationsControllerService.ItemPublicationControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    context?: HttpContext
    body?: ItemPublicationPartialWithRelations
  }
): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation itemPublicationControllerCreate
   */
  static readonly ItemPublicationControllerCreatePath = '/admin/item-publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    context?: HttpContext
    body?: ItemPublicationPartialWithRelations
  }
): Observable<StrictHttpResponse<ItemPublicationWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemPublicationsControllerService.ItemPublicationControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ItemPublicationWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    context?: HttpContext
    body?: ItemPublicationPartialWithRelations
  }
): Observable<ItemPublicationWithRelations> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ItemPublicationWithRelations>) => r.body as ItemPublicationWithRelations)
    );
  }

}
