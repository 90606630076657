/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FileWithRelations } from '../models/file-with-relations';
import { Item } from '../models/item';
import { ItemAuctionWithRelations } from '../models/item-auction-with-relations';
import { ItemExhibitionWithRelations } from '../models/item-exhibition-with-relations';
import { ItemOwnerWithRelations } from '../models/item-owner-with-relations';
import { ItemPublicationWithRelations } from '../models/item-publication-with-relations';
import { ItemWithRelations } from '../models/item-with-relations';
import { NewItem } from '../models/new-item';
import { RelationPair } from '../models/relation-pair';
import { UpdateItem } from '../models/update-item';

@Injectable({
  providedIn: 'root',
})
export class AdminItemsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation itemControllerAdminGetInventoryItems
   */
  static readonly ItemControllerAdminGetInventoryItemsPath = '/admin/inventory/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetInventoryItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetInventoryItems$Response(params: {
    inventoryId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminGetInventoryItemsPath, 'get');
    if (params) {
      rb.query('inventoryId', params.inventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetInventoryItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetInventoryItems(params: {
    inventoryId: string;
    context?: HttpContext
  }
): Observable<Array<ItemWithRelations>> {

    return this.adminGetInventoryItems$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemWithRelations>>) => r.body as Array<ItemWithRelations>)
    );
  }

  /**
   * Path part for operation itemControllerAdminDeleteItemDocument
   */
  static readonly ItemControllerAdminDeleteItemDocumentPath = '/admin/items/documents/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteItemDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteItemDocument$Response(params: {
    id: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminDeleteItemDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('inventoryId', params.inventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDeleteItemDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteItemDocument(params: {
    id: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.adminDeleteItemDocument$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation itemControllerAdminGetDocuments
   */
  static readonly ItemControllerAdminGetDocumentsPath = '/admin/items/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetDocuments$Response(params: {
    itemId?: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FileWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminGetDocumentsPath, 'get');
    if (params) {
      rb.query('itemId', params.itemId, {});
      rb.query('inventoryId', params.inventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FileWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetDocuments(params: {
    itemId?: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<Array<FileWithRelations>> {

    return this.adminGetDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>) => r.body as Array<FileWithRelations>)
    );
  }

  /**
   * Path part for operation itemControllerAdminUploadDocuments
   */
  static readonly ItemControllerAdminUploadDocumentsPath = '/admin/items/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUploadDocuments()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminUploadDocuments$Response(params: {
    itemId?: string;
    inventoryId: string;
    isPublic?: boolean;
    authorId?: string;
    title?: string;
    titleEN?: string;
    description?: string;
    descriptionEN?: string;
    notesInternal?: string;
    documentTypeId?: string;
    context?: HttpContext

    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminUploadDocumentsPath, 'post');
    if (params) {
      rb.query('itemId', params.itemId, {});
      rb.query('inventoryId', params.inventoryId, {});
      rb.query('isPublic', params.isPublic, {});
      rb.query('authorId', params.authorId, {});
      rb.query('title', params.title, {});
      rb.query('titleEN', params.titleEN, {});
      rb.query('description', params.description, {});
      rb.query('descriptionEN', params.descriptionEN, {});
      rb.query('notesInternal', params.notesInternal, {});
      rb.query('documentTypeId', params.documentTypeId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminUploadDocuments$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminUploadDocuments(params: {
    itemId?: string;
    inventoryId: string;
    isPublic?: boolean;
    authorId?: string;
    title?: string;
    titleEN?: string;
    description?: string;
    descriptionEN?: string;
    notesInternal?: string;
    documentTypeId?: string;
    context?: HttpContext

    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
  }
): Observable<void> {

    return this.adminUploadDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation itemControllerAddParent
   */
  static readonly ItemControllerAddParentPath = '/admin/items/parent/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  addParent$Response(params: {
    itemId: string;
    parentId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAddParentPath, 'post');
    if (params) {
      rb.query('itemId', params.itemId, {});
      rb.query('parentId', params.parentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addParent(params: {
    itemId: string;
    parentId: string;
    context?: HttpContext
  }
): Observable<Item> {

    return this.addParent$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Path part for operation itemControllerRemoveParent
   */
  static readonly ItemControllerRemoveParentPath = '/admin/items/parent/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeParent$Response(params: {
    itemId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerRemoveParentPath, 'post');
    if (params) {
      rb.query('itemId', params.itemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeParent(params: {
    itemId: string;
    context?: HttpContext
  }
): Observable<Item> {

    return this.removeParent$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Path part for operation itemControllerAdminDeleteItemPicture
   */
  static readonly ItemControllerAdminDeleteItemPicturePath = '/admin/items/pictures/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteItemPicture()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteItemPicture$Response(params: {
    id: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminDeleteItemPicturePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('inventoryId', params.inventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDeleteItemPicture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteItemPicture(params: {
    id: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.adminDeleteItemPicture$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation itemControllerAdminGetPictures
   */
  static readonly ItemControllerAdminGetPicturesPath = '/admin/items/pictures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPictures()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPictures$Response(params: {
    itemId?: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FileWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminGetPicturesPath, 'get');
    if (params) {
      rb.query('itemId', params.itemId, {});
      rb.query('inventoryId', params.inventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FileWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetPictures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPictures(params: {
    itemId?: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<Array<FileWithRelations>> {

    return this.adminGetPictures$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>) => r.body as Array<FileWithRelations>)
    );
  }

  /**
   * Path part for operation itemControllerAdminUploadPictures
   */
  static readonly ItemControllerAdminUploadPicturesPath = '/admin/items/pictures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUploadPictures()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminUploadPictures$Response(params: {
    itemId?: string;
    inventoryId: string;
    isPublic?: boolean;
    order?: number;
    authorId?: string;
    title?: string;
    titleEN?: string;
    description?: string;
    descriptionEN?: string;
    copyright?: string;
    copyrightEN?: string;
    notesInternal?: string;
    context?: HttpContext

    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<Array<FileWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminUploadPicturesPath, 'post');
    if (params) {
      rb.query('itemId', params.itemId, {});
      rb.query('inventoryId', params.inventoryId, {});
      rb.query('isPublic', params.isPublic, {});
      rb.query('order', params.order, {});
      rb.query('authorId', params.authorId, {});
      rb.query('title', params.title, {});
      rb.query('titleEN', params.titleEN, {});
      rb.query('description', params.description, {});
      rb.query('descriptionEN', params.descriptionEN, {});
      rb.query('copyright', params.copyright, {});
      rb.query('copyrightEN', params.copyrightEN, {});
      rb.query('notesInternal', params.notesInternal, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FileWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminUploadPictures$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminUploadPictures(params: {
    itemId?: string;
    inventoryId: string;
    isPublic?: boolean;
    order?: number;
    authorId?: string;
    title?: string;
    titleEN?: string;
    description?: string;
    descriptionEN?: string;
    copyright?: string;
    copyrightEN?: string;
    notesInternal?: string;
    context?: HttpContext

    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
  }
): Observable<Array<FileWithRelations>> {

    return this.adminUploadPictures$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>) => r.body as Array<FileWithRelations>)
    );
  }

  /**
   * Path part for operation i2RelationControllerCreateRelation
   */
  static readonly I2RelationControllerCreateRelationPath = '/admin/items/relation/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRelation()` instead.
   *
   * This method doesn't expect any request body.
   */
  createRelation$Response(params?: {
    itemId?: string;
    targetItemId?: string;
    relationTypeId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<RelationPair>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.I2RelationControllerCreateRelationPath, 'post');
    if (params) {
      rb.query('itemId', params.itemId, {});
      rb.query('targetItemId', params.targetItemId, {});
      rb.query('relationTypeId', params.relationTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RelationPair>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRelation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createRelation(params?: {
    itemId?: string;
    targetItemId?: string;
    relationTypeId?: string;
    context?: HttpContext
  }
): Observable<RelationPair> {

    return this.createRelation$Response(params).pipe(
      map((r: StrictHttpResponse<RelationPair>) => r.body as RelationPair)
    );
  }

  /**
   * Path part for operation i2RelationControllerDeleteRelation
   */
  static readonly I2RelationControllerDeleteRelationPath = '/admin/items/relation/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRelation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelation$Response(params?: {
    relationId?: string;
    itemId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.I2RelationControllerDeleteRelationPath, 'delete');
    if (params) {
      rb.query('relationId', params.relationId, {});
      rb.query('itemId', params.itemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRelation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelation(params?: {
    relationId?: string;
    itemId?: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.deleteRelation$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation itemAuctionControllerAdminGetAuctionsListForItem
   */
  static readonly ItemAuctionControllerAdminGetAuctionsListForItemPath = '/admin/items/{id}/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAuctionsListForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAuctionsListForItem$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemAuctionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemAuctionControllerAdminGetAuctionsListForItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemAuctionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetAuctionsListForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAuctionsListForItem(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<ItemAuctionWithRelations>> {

    return this.adminGetAuctionsListForItem$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemAuctionWithRelations>>) => r.body as Array<ItemAuctionWithRelations>)
    );
  }

  /**
   * Path part for operation itemExhibitionControllerAdminGetExhibitionsListForItem
   */
  static readonly ItemExhibitionControllerAdminGetExhibitionsListForItemPath = '/admin/items/{id}/exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetExhibitionsListForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExhibitionsListForItem$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemExhibitionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemExhibitionControllerAdminGetExhibitionsListForItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemExhibitionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetExhibitionsListForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExhibitionsListForItem(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<ItemExhibitionWithRelations>> {

    return this.adminGetExhibitionsListForItem$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemExhibitionWithRelations>>) => r.body as Array<ItemExhibitionWithRelations>)
    );
  }

  /**
   * Path part for operation itemOwnerControllerAdminGetOwnersListForItem
   */
  static readonly ItemOwnerControllerAdminGetOwnersListForItemPath = '/admin/items/{id}/owners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOwnersListForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOwnersListForItem$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemOwnerWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemOwnerControllerAdminGetOwnersListForItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemOwnerWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetOwnersListForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOwnersListForItem(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<ItemOwnerWithRelations>> {

    return this.adminGetOwnersListForItem$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemOwnerWithRelations>>) => r.body as Array<ItemOwnerWithRelations>)
    );
  }

  /**
   * Path part for operation itemPublicationControllerAdminGetPublicationsListForItem
   */
  static readonly ItemPublicationControllerAdminGetPublicationsListForItemPath = '/admin/items/{id}/publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPublicationsListForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPublicationsListForItem$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemPublicationWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemPublicationControllerAdminGetPublicationsListForItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemPublicationWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminGetPublicationsListForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPublicationsListForItem(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<ItemPublicationWithRelations>> {

    return this.adminGetPublicationsListForItem$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemPublicationWithRelations>>) => r.body as Array<ItemPublicationWithRelations>)
    );
  }

  /**
   * Path part for operation itemControllerAdminFindById
   */
  static readonly ItemControllerAdminFindByIdPath = '/admin/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params?: {
    itemId?: string;
    inventoryId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ItemWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminFindByIdPath, 'get');
    if (params) {
      rb.query('itemId', params.itemId, {});
      rb.query('inventoryId', params.inventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ItemWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params?: {
    itemId?: string;
    inventoryId?: string;
    context?: HttpContext
  }
): Observable<ItemWithRelations> {

    return this.adminFindById$Response(params).pipe(
      map((r: StrictHttpResponse<ItemWithRelations>) => r.body as ItemWithRelations)
    );
  }

  /**
   * Path part for operation itemControllerCreate
   */
  static readonly ItemControllerCreatePath = '/admin/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {
    inventoryId: string;
    forceNumber?: number;
    context?: HttpContext
    body?: NewItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerCreatePath, 'post');
    if (params) {
      rb.query('inventoryId', params.inventoryId, {});
      rb.query('forceNumber', params.forceNumber, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {
    inventoryId: string;
    forceNumber?: number;
    context?: HttpContext
    body?: NewItem
  }
): Observable<Item> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Path part for operation itemControllerAdminDeleteById
   */
  static readonly ItemControllerAdminDeleteByIdPath = '/admin/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: {
    itemId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminDeleteByIdPath, 'delete');
    if (params) {
      rb.path('itemId', params.itemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: {
    itemId: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.adminDeleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation itemControllerAdminUpdateById
   */
  static readonly ItemControllerAdminUpdateByIdPath = '/admin/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateById$Response(params: {
    itemId?: string;
    inventoryId: string;
    context?: HttpContext
    body?: UpdateItem
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AdminItemsControllerService.ItemControllerAdminUpdateByIdPath, 'patch');
    if (params) {
      rb.query('itemId', params.itemId, {});
      rb.query('inventoryId', params.inventoryId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminUpdateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateById(params: {
    itemId?: string;
    inventoryId: string;
    context?: HttpContext
    body?: UpdateItem
  }
): Observable<any> {

    return this.adminUpdateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
