import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { UserControllerService } from "../api/services";
import { TokenStorageService } from "./token-storage.service";
import { AuthRole, UserInfo } from "../api/models";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUser: BehaviorSubject<UserInfo | undefined> =
    new BehaviorSubject<UserInfo | undefined>(undefined);
  private isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  currentUser$: Observable<UserInfo | undefined> = this.currentUser;
  isLoggedIn$: Observable<boolean> = this.isLoggedIn;

  isLoginFailed: boolean = false;
  errorMessage: string = "";

  constructor(
    private ucService: UserControllerService,
    private tsService: TokenStorageService
  ) {
    this.currentUser.next(this.tsService.getUser());
    this.isLoggedIn.next(this.tsService.getToken() ? true : false);
  }

  get isLoggedInValue(): boolean {
    return this.isLoggedIn.value;
  }

  get currentUserValue(): UserInfo | undefined {
    return this.currentUser.value;
  }

  get currentUserRole(): AuthRole {
    const userRole: AuthRole =
      this.currentUser.value && this.currentUser.value.role;
    return userRole;
  }

  async login(username: string, password: string): Promise<boolean> {
    const login$ = this.ucService.login({
      body: { email: username, password: password },
    });
    const isLoggedIn = await lastValueFrom(login$)
      .catch((reason) => {
        console.error("Login Failed", reason);
        return false;
      })
      .then(async (response: any) => {
        if (response.token) {
          this.tsService.saveToken(response.token);
          await this.saveUser();
          this.isLoggedIn.next(true);
          this.isLoginFailed = false;
          return true;
        } else {
          return false;
        }
      });
    return isLoggedIn;
  }

  logout() {
    this.isLoggedIn.next(false);
    this.currentUser.next(undefined);
    this.tsService.clearStorage();
  }

  checkUserAccessRightByRole(role: string): boolean {
    const userRole = this.currentUser.value && this.currentUser.value.role;

    if (!userRole || userRole.id !== role) {
      return false;
    } else {
      return true;
    }
  }

  async saveUser() {
    const userToken = this.tsService.getToken();
    if (!userToken) {
      console.error("No User");
    }
    if (userToken) {
      await this.ucService.whoAmI().subscribe((user) => {
        console.log("Logged In User", user);
        console.log(userToken);


        this.currentUser.next(user);
        this.tsService.saveUser(user);
      });
    }
  }
}
