/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Author } from '../models/author';
import { AuthorPartial } from '../models/author-partial';
import { AuthorWithRelations } from '../models/author-with-relations';
import { NewAuthor } from '../models/new-author';

@Injectable({
  providedIn: 'root',
})
export class EditorAuthorControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authorControllerEditorGetAuthor
   */
  static readonly AuthorControllerEditorGetAuthorPath = '/editor/author';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorGetAuthor()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetAuthor$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AuthorWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, EditorAuthorControllerService.AuthorControllerEditorGetAuthorPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthorWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editorGetAuthor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetAuthor(params: {
    id: string;
    context?: HttpContext
  }
): Observable<AuthorWithRelations> {

    return this.editorGetAuthor$Response(params).pipe(
      map((r: StrictHttpResponse<AuthorWithRelations>) => r.body as AuthorWithRelations)
    );
  }

  /**
   * Path part for operation authorControllerEditorGetAuthors
   */
  static readonly AuthorControllerEditorGetAuthorsPath = '/editor/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorGetAuthors()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetAuthors$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AuthorWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, EditorAuthorControllerService.AuthorControllerEditorGetAuthorsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AuthorWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editorGetAuthors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetAuthors(params?: {
    context?: HttpContext
  }
): Observable<Array<AuthorWithRelations>> {

    return this.editorGetAuthors$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AuthorWithRelations>>) => r.body as Array<AuthorWithRelations>)
    );
  }

  /**
   * Path part for operation authorControllerEditorCreateAuthor
   */
  static readonly AuthorControllerEditorCreateAuthorPath = '/editor/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorCreateAuthor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorCreateAuthor$Response(params?: {
    context?: HttpContext
    body?: NewAuthor
  }
): Observable<StrictHttpResponse<Author>> {

    const rb = new RequestBuilder(this.rootUrl, EditorAuthorControllerService.AuthorControllerEditorCreateAuthorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Author>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editorCreateAuthor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorCreateAuthor(params?: {
    context?: HttpContext
    body?: NewAuthor
  }
): Observable<Author> {

    return this.editorCreateAuthor$Response(params).pipe(
      map((r: StrictHttpResponse<Author>) => r.body as Author)
    );
  }

  /**
   * Path part for operation authorControllerEditorDeleteAuthor
   */
  static readonly AuthorControllerEditorDeleteAuthorPath = '/editor/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorDeleteAuthor()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorDeleteAuthor$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, EditorAuthorControllerService.AuthorControllerEditorDeleteAuthorPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editorDeleteAuthor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorDeleteAuthor(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.editorDeleteAuthor$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation authorControllerEditorUpdateAuthor
   */
  static readonly AuthorControllerEditorUpdateAuthorPath = '/editor/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorUpdateAuthor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorUpdateAuthor$Response(params: {
    id: string;
    context?: HttpContext
    body?: AuthorPartial
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, EditorAuthorControllerService.AuthorControllerEditorUpdateAuthorPath, 'patch');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editorUpdateAuthor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorUpdateAuthor(params: {
    id: string;
    context?: HttpContext
    body?: AuthorPartial
  }
): Observable<any> {

    return this.editorUpdateAuthor$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
